/* You can add global styles to this file, and also import other style files */

$common-font: 'Poppins', sans-serif;
$heading-font: 'Playfair Display', serif;
$transition: .5s all ease;
$all-size: 18px;
$white-color: #FFFFE9;
$black-color: #000000;
$gray-color: #a5a4a9;
$olive-color: #808000;

/*-- Default CSS --*/
body {
    font-family: $common-font;
    color: $gray-color;
    background-color: $white-color;
    font-size: $all-size;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.3;
    font-family: $heading-font;
    color: $black-color;
}

p {
    line-height: 1.7;
}

a {
    transition: $transition;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

img {
    max-width: 100%;
}

.d-table {
    width: 100%;
    height: 100%;
}

.d-table-cell {
    vertical-align: middle;
}

.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.pt-100 {
    padding-top: 100px;
}

.pb-70 {
    padding-bottom: 70px;
}

.pb-100 {
    padding-bottom: 100px;
}

button {
    &:focus {
        outline: 0;
    }
}

.btn.focus,
.btn:focus {
    box-shadow: none;
}

/*-- End Default CSS --*/

/*-- Navbar --*/
.navbar-area {
    .side-nav {
        position: relative;
        top: 4px;

        .nav-cart {
            width: 42px;
            height: 42px;
            line-height: 48px;
            border-radius: 50%;
            border: 1px solid $black-color;
            color: $black-color;
            display: inline-block;
            text-align: center;
            font-size: 22px;
            position: relative;

            &:hover {
                background-color: $olive-color;
                border: 1px solid $olive-color;
                color: $white-color;

                span {
                    color: $olive-color;
                    background-color: $white-color;
                }
            }

            span {
                display: inline-block;
                color: $white-color;
                background-color: $black-color;
                border-radius: 50%;
                width: 18px;
                height: 18px;
                line-height: 18px;
                font-size: 12px;
                position: absolute;
                top: -3px;
                right: -5px;
                font-weight: 500;
            }
        }

        .nav-tel {
            color: $black-color;
            border-radius: 50px;
            padding: 12px 15px 11px 15px;
            background-color: $olive-color;
            font-size: 14px;
            position: relative;
            top: -6px;
            margin-left: 15px;
            display: inline-block;

            i {
                display: inline-block;
                margin-right: 5px;
                font-size: 18px;
                position: relative;
                top: 3px;
            }

            &:hover {
                color: $white-color;
                background-color: $black-color;
            }
        }
    }
}

.main-nav {
    background: transparent;
    transition: $transition;

    nav {

        .navbar-brand {
            position: absolute;
            max-width: 200px;
        }

        .navbar-nav {
            margin-left: auto;
            margin-right: auto;

            .nav-link {

                &:focus,
                &:hover {
                    color: $olive-color;
                }
            }

            .nav-item {
                a {
                    font-weight: 500;
                    font-size: 20px;
                    color: $black-color;
                    font-family: $common-font;
                    text-transform: unset;

                    &:hover,
                    &:focus,
                    &.active {
                        color: $olive-color;
                    }

                    &:hover {
                        i {
                            transform: rotate(0deg);
                            color: $olive-color;
                        }
                    }

                    i {
                        display: inline-block;
                        font-size: 18px;
                        position: relative;
                        top: 4px;
                        transform: rotate(-90deg);
                        transition: $transition;
                    }
                }

                .dropdown-menu {
                    background: $white-color;
                    padding-left: 10px;
                    padding-right: 10px;

                    li {
                        border-bottom: 1px solid #0b032026;
                        position: relative;
                        transition: $transition;

                        &:hover {
                            padding-left: 10px;

                            &:before {
                                opacity: 1;
                            }

                            a {
                                color: $olive-color;
                                text-transform: unset;
                            }
                        }

                        &:before {
                            position: absolute;
                            content: '';
                            width: 15px;
                            height: 1px;
                            left: 0;
                            top: 20px;
                            background-color: $olive-color;
                            opacity: 0;
                            transition: $transition;
                        }

                        &:last-child {
                            border-bottom: 0;
                        }

                        a {
                            color: $black-color;

                            &:hover,
                            &:focus,
                            &.active {
                                color: $olive-color;
                            }
                        }
                    }
                }
            }
        }
    }

    .dropdown-toggle::after {
        display: none;
    }
}

.menu-shrink {
    background-color: $white-color;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: 0px 0px 20px 0px #dddddd8c;
}

/*-- End Navbar --*/

/*-- Banner --*/
.banner-area {
    height: 900px;
    position: relative;

    .banner-shape {
        img {
            position: absolute;
            z-index: -1;

            &:nth-child(1) {
                bottom: 125px;
                left: 165px;
            }

            &:nth-child(2) {
                top: 130px;
                left: 170px;
                margin-left: auto;
                margin-right: auto;
            }

            &:nth-child(3) {
                bottom: 30px;
                left: 0;
                right: 20px;
                margin-left: auto;
                margin-right: auto;
            }

            &:nth-child(4) {
                top: 275px;
                right: 0;
                max-width: 140px;
            }
        }
    }

    .banner-content {
        h1 {
            font-weight: 700;
            font-size: 60px;
            margin-bottom: 20px;
        }

        p {
            margin-bottom: 30px;
        }

        form {
            position: relative;

            ::placeholder {
                color: $gray-color;
            }

            .form-control {
                font-size: 16px;
                padding-left: 25px;
                border: 0;
                border-radius: 50px;
                box-shadow: 0px 0px 20px 0px $gray-color;
                height: 70px;
                padding-top: 10px;
                padding-bottom: 10px;

                &:focus {
                    box-shadow: 0px 0px 20px 0px #dddddd5c;
                    border: 0;
                }
            }

            .banner-form-btn {
                font-weight: 500;
                font-size: $all-size;
                color: $black-color;
                border-radius: 50px;
                background-color: $olive-color;
                padding: 12px 32px;
                position: absolute;
                top: 10px;
                right: 12px;
                transition: $transition;

                &:hover {
                    color: $white-color;
                    background-color: $black-color;
                }
            }
        }
    }

    .owl-theme .owl-nav {
        margin-top: -5px;
        position: relative;
        right: 0;
        left: 0;
    }

    .banner-slider {
        .owl-prev {
            font-size: 55px !important;
            color: $olive-color !important;
            transition: $transition;

            &:hover {
                color: $black-color !important;
                background-color: transparent !important;
            }
        }

        .owl-next {
            font-size: 55px !important;
            color: $olive-color !important;
            transition: $transition;

            &:hover {
                color: $black-color !important;
                background-color: transparent !important;
            }
        }
    }
}

/*-- End Banner --*/

/*-- Feature --*/
.section-title {
    margin-bottom: 50px;
    text-align: center;
    margin-top: -8px;

    .sub-title {
        font-weight: 500;
        font-size: 16px;
        color: $olive-color;
        display: block;
        margin-bottom: 8px;
    }

    h2 {
        font-weight: 700;
        font-size: 35px;
        margin-bottom: 16px;
    }

    p {
        max-width: 580px;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
    }
}

.feature-area {
    .section-title {
        text-align: left;

        p {
            margin-left: 0;
        }
    }
}

.feature-item {
    position: relative;
    margin-bottom: 30px;
    transition: $transition;

    img {
        width: 100%;
        border-radius: 25px;
    }

    &:hover {
        transform: translate(0, -10px);

        .feature-inner {
            ul {
                li {
                    a {
                        background-color: $black-color;
                    }
                }
            }
        }
    }

    .feature-inner {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 30px;
        background-color: $white-color;
        max-width: 250px;
        max-height: 60px;
        border-radius: 50px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 25px;
        padding-top: 12px;
        padding-bottom: 12px;

        ul {
            margin: 0;
            padding: 0;
            position: relative;

            li {
                list-style-type: none;
                display: inline-block;

                img {
                    position: relative;
                    top: -2px;
                }

                span {
                    display: block;
                    font-weight: 500;
                    font-size: 18px;
                    color: $black-color;
                    margin-left: 12px;
                }

                a {
                    display: block;
                    width: 40px;
                    height: 40px;
                    line-height: 46px;
                    border-radius: 50%;
                    text-align: center;
                    font-size: 23px;
                    color: $white-color;
                    background-color: $olive-color;
                    position: absolute;
                    right: 7px;
                    top: -7px;
                }
            }
        }
    }
}

/*-- End Feature --*/

/*-- Service --*/
.services-area {
    background-color: $white-color;

    .services-item {
        text-align: center;
        padding: 32px 20px 30px;
        border-radius: 25px;
        transition: $transition;
        position: relative;
        height: 250px;

        a {
            display: block;
        }

        &:hover {
            background-color: $olive-color;
            cursor: pointer;

            .service-shape {
                opacity: 1;
            }

            h3 {
                color: $white-color;
            }

            p {
                color: $white-color;
            }
        }

        img {
            margin-bottom: 20px;
            width: 80px !important;
            height: 80px;
            margin-left: auto;
            margin-right: auto;
            display: block;
        }

        .service-shape {
            position: absolute;
            top: 0;
            left: 0;
            transition: $transition;
            opacity: 0;
            width: 100% !important;
            height: 100% !important;
            margin-bottom: 0;
        }

        h3 {
            font-weight: 700;
            font-size: 22px;
            margin-bottom: 15px;
            transition: $transition;
            color: $black-color;
        }

        p {
            margin-bottom: 0;
            transition: $transition;
            color: $gray-color;
        }
    }

    .services-slider {
        margin-bottom: -9px;

        .center {
            background-color: $olive-color;
            border-radius: 25px;

            .service-shape {
                opacity: 1;
            }

            h3 {
                color: $white-color;
            }

            p {
                color: $white-color;
            }
        }

        .owl-prev {
            height: 40px;
            width: 40px;
            line-height: 45px !important;
            border-radius: 50% !important;
            text-align: center;
            color: $white-color !important;
            background-color: #ffe7a2 !important;
            font-size: 25px !important;
            transition: $transition;
            position: absolute;
            top: 45%;
            left: -55px;
            opacity: 0;

            &:hover {
                background-color: $olive-color !important;
            }
        }

        .owl-next {
            height: 40px;
            width: 40px;
            line-height: 45px !important;
            border-radius: 50% !important;
            text-align: center;
            color: $white-color !important;
            background-color: #ffe7a2 !important;
            font-size: 25px !important;
            transition: $transition;
            position: absolute;
            top: 45%;
            right: -55px;
            opacity: 0;

            &:hover {
                background-color: $olive-color !important;
            }
        }

        &:hover {
            .owl-prev {
                opacity: 1;
                left: -48px;
            }

            .owl-next {
                opacity: 1;
                right: -48px;
            }
        }
    }
}

/*-- End Service --*/

/*-- Restant--*/
.restant-area {
    padding-top: 150px;
    position: relative;
    padding-bottom: 130px;

    .restant-shape {
        img {
            position: absolute;
            right: 0;
            bottom: 0;
            max-width: 150px;
        }
    }

    .restant-content {
        max-width: 600px;
        padding-left: 20px;

        .section-title {
            text-align: left;
            margin-bottom: 35px;

            p {
                max-width: 100%;
                margin-left: 0;
                margin-bottom: 10px;
            }
        }
    }

    .restant-img {
        text-align: center;
        position: relative;
        max-width: 620px;
        margin-left: auto;
        margin-right: auto;

        img {
            &:nth-child(1) {
                position: relative;
                max-width: 520px;
                margin-left: auto;
                margin-right: auto;
            }

            &:nth-child(2) {
                position: absolute;
                left: 0;
                right: 0;
                top: -50px;
                margin-left: auto;
                margin-right: auto;
                max-width: 280px;
                animation: restant-amination 10s infinite linear;
            }

            &:nth-child(3) {
                position: absolute;
                top: 175px;
                right: -50px;
                max-width: 260px;
                animation: restant-amination 10s infinite linear;
            }

            &:nth-child(4) {
                position: absolute;
                left: 0;
                right: 0;
                max-width: 260px;
                bottom: -100px;
                margin-left: auto;
                margin-right: auto;
                animation: restant-amination 10s infinite linear;
            }

            &:nth-child(5) {
                position: absolute;
                top: 160px;
                left: -65px;
                max-width: 275px;
                animation: restant-amination 10s infinite linear;
            }
        }
    }
}

.cmn-btn {
    font-weight: 500;
    font-size: $all-size;
    color: $black-color;
    background-color: $olive-color;
    padding: 12px 30px;
    border-radius: 50px;
    display: inline-block;

    &:hover {
        background-color: $black-color;
        color: $white-color;
    }
}

@keyframes restant-amination {
    0% {
        transform: rotate(0deg);
    }

    40% {
        transform: rotate(10deg);
    }

    70% {
        transform: rotate(-15deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

/*-- End Restant --*/

/*-- Collection --*/
.collection-area {
    .more-collection {
        text-align: center;

        a {
            display: inline-block;
            font-weight: 500;
            color: $black-color;
            font-size: 18px;
            border-bottom: 1px solid $black-color;

            &:hover {
                color: $olive-color;
                border-bottom: 1px solid $olive-color;
            }
        }
    }
}

.collection-item {
    margin-bottom: 30px;
    box-shadow: 0px 0px 20px 0px #dddddd82;
    border-radius: 10px 10px 0px 0px;

    &:hover {
        .collection-top {
            ul {
                opacity: 1;
                bottom: 0;
            }

            .add-cart {
                right: 10px;

                a {
                    opacity: 1;
                }
            }
        }
    }

    .collection-top {
        position: relative;

        img {
            width: 100%;
            border-radius: 10px 10px 0px 0px;
        }

        ul {
            margin: 0;
            padding: 0;
            background-color: $white-color;
            position: absolute;
            bottom: -10px;
            left: 0;
            right: 0;
            opacity: 0;
            padding: 4px 15px;
            border-radius: 8px 8px 0 0;
            max-width: 128px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            box-shadow: 0px 6px 15px 0px #dddddd40;
            transition: $transition;

            li {
                list-style-type: none;
                display: inline-block;

                i {
                    color: $gray-color;
                    display: block;
                }

                .checked {
                    color: #ffc107;
                }
            }
        }

        .add-cart {
            display: inline-block;
            position: absolute;
            top: 10px;
            right: -15px;

            a {
                display: block;
                color: $black-color;
                background-color: $white-color;
                border-radius: 5px;
                padding: 4px 12px 8px;
                font-size: 13px;
                opacity: 0;

                i {
                    display: inline-block;
                    color: $olive-color;
                    font-size: 22px;
                    position: relative;
                    top: 4px;
                    margin-right: 3px;
                }

                &:hover {
                    color: $white-color;
                    background-color: $black-color;
                }
            }
        }
    }

    .collection-bottom {
        padding: 15px 20px 17px;

        h3 {
            font-weight: 500;
            font-size: 20px;
            margin-bottom: 15px;
            font-family: $common-font;
        }

        ul {
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;

            li {
                list-style-type: none;
                display: inline-block;

                &:first-child {
                    flex: 0 0 20%;
                    max-width: 20%;
                }

                &:last-child {
                    text-align: right;
                    flex: 0 0 80%;
                    max-width: 80%;
                }

                span {
                    display: block;
                    font-weight: 500;
                    font-size: 20px;
                    color: #fe3333;
                    position: relative;
                    top: 4px;
                }

                .minus,
                .plus {
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    color: $white-color;
                    background-color: $olive-color;
                    display: inline-block;
                    text-align: center;
                    cursor: pointer;
                    margin-bottom: 0;
                    vertical-align: middle;
                    transition: $transition;
                    border-radius: 10px;
                    top: 0;

                    &:hover {
                        background-color: $olive-color;
                    }
                }

                .form-control {
                    height: 25px;
                    width: 50px;
                    text-align: center;
                    font-size: 20px;
                    font-weight: 500;
                    border: 0;
                    color: $olive-color;
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: -4px;
                    margin-right: -4px;

                    &:focus {
                        box-shadow: none;
                        border: 0;
                    }
                }
            }
        }
    }
}

.sorting-menu {
    ul {
        margin: 0;
        padding: 0;
        text-align: center;
        margin-bottom: 40px;

        li {
            list-style-type: none;
            display: inline-block;
            font-size: $all-size;
            color: $black-color;
            cursor: pointer;
            border-radius: 50px;
            padding: 10px 20px;
            margin-left: 5px;
            margin-right: 5px;
            transition: $transition;
            font-weight: 500;
            background-color: $white-color;
            margin-bottom: 7px;

            &:hover,
            &.mixitup-control-active {
                color: $white-color;
                background-color: $olive-color;
            }
        }
    }
}

/*-- End Collection --*/

/*-- Menu --*/
.menu-area {
    background-color: $gray-color;
}

.menu-item {
    text-align: center;
    margin-bottom: 30px;
    background-color: $white-color;
    padding: 45px 30px 42px;
    border-radius: 18px;
    position: relative;
    transition: $transition;
    border: 1px solid $olive-color;

    &:hover,
    &.active {
        background-color: $olive-color;
        transform: translate(0, -10px);

        .menu-shape {
            opacity: 1;
        }

        h3 {
            color: $white-color;
        }
    }

    img {
        margin-bottom: 22px;
    }

    .menu-shape {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        margin-bottom: 0;
        transition: $transition;
        width: 100%;
        height: 100%;
    }

    h3 {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 0;
        transition: $transition;
    }
}

/*-- End Menu --*/

/*-- Reservation --*/
.reservation-area {
    background-color: $black-color;
    border-radius: 0 0 85px 0;
    position: relative;

    .reservation-shape {
        img {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .col-lg-6 {
        position: relative;
    }

    .row {
        position: relative;
    }

    .reservation-item {
        padding-top: 100px;
        padding-bottom: 100px;
        position: relative;

        .section-title {
            text-align: left;
            margin-bottom: 40px;

            h2 {
                color: $white-color;
            }

            p {
                margin-left: 0;
                color: $white-color;
            }
        }

        ul {
            margin: 0;
            padding: 10px 11px 10px 20px;
            background-color: $white-color;
            border-radius: 50px;
            text-align: center;

            li {
                list-style-type: none;
                display: inline-block;

                .form-control {
                    width: 165px;
                    font-size: 13px;
                    border: 1px solid $black-color;

                    &:focus {
                        box-shadow: none;
                        border: 1px solid $black-color;
                    }
                }

                &:first-child {
                    padding-right: 10px;
                    position: relative;
                    margin-right: 5px;

                    &:before {
                        position: absolute;
                        content: '';
                        width: 1px;
                        height: 45px;
                        right: 0;
                        top: -6px;
                        background-color: $olive-color;
                    }
                }

                &:last-child {
                    .cmn-btn {
                        padding: 12px 22px;
                        margin-left: 7px;
                    }
                }
            }
        }
    }

    .reservation-img {
        text-align: center;
        position: absolute;
        right: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto;
        top: -225px;
    }
}

/*-- End Reservation --*/

/*-- Chef --*/
.chef-area {
    padding-top: 190px;
}

.chef-item {
    margin-bottom: 30px;
    text-align: center;

    &:hover,
    &.active {
        .chef-bottom {
            ul {
                li {
                    &:nth-child(1) {
                        bottom: 0;
                        opacity: 1;
                        z-index: 1;
                    }

                    &:nth-child(2) {
                        bottom: 0;
                        opacity: 1;
                        z-index: 1;
                    }

                    &:nth-child(3) {
                        bottom: 0;
                        opacity: 1;
                        z-index: 1;
                    }
                }
            }
        }
    }

    .chef-top {
        position: relative;
        border-radius: 10px 10px 0 0;

        img {
            width: 100%;
            border-radius: 10px 10px 0 0;
        }

        .chef-inner {
            background-color: $black-color;
            border-radius: 10px 10px 0 0;
            padding: 10px 10px 12px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;

            h3 {
                color: $white-color;
                font-family: $common-font;
                font-weight: 500;
                font-size: 20px;
                margin-bottom: 5px;
            }

            span {
                display: block;
                font-size: 14px;
                color: $white-color;
            }
        }
    }

    .chef-bottom {
        ul {
            margin: 0;
            padding: 0;
            margin-top: 10px;

            li {
                list-style-type: none;
                display: inline-block;
                margin-left: 5px;
                margin-right: 5px;

                &:nth-child(1) {
                    position: relative;
                    bottom: -10px;
                    opacity: 0;
                    z-index: -1;
                    transition: $transition;
                }

                &:nth-child(2) {
                    position: relative;
                    bottom: -10px;
                    opacity: 0;
                    z-index: -1;
                    transition: .7s all ease;
                }

                &:nth-child(3) {
                    position: relative;
                    bottom: -10px;
                    opacity: 0;
                    z-index: -1;
                    transition: .9s all ease;
                }

                a {
                    display: block;
                    width: 35px;
                    height: 35px;
                    line-height: 40px;
                    border-radius: 50%;
                    text-align: center;
                    color: $white-color;
                    background-color: $black-color;
                    font-size: 18px;

                    &:hover {
                        background-color: $olive-color;
                    }
                }
            }
        }
    }
}

/*-- End Chef --*/

/*-- Review --*/
.review-area {
    position: relative;

    .slick-prev {
        left: 0;

        &:before {
            display: none;
        }

        i {
            color: $olive-color;
            display: block;
            font-size: 35px;
            position: absolute;
            bottom: -98px;
            left: 240px;
            transition: $transition;

            &:hover {
                color: $black-color;
            }
        }
    }

    .slick-next {
        right: 0;

        &:before {
            display: none;
        }

        i {
            color: $olive-color;
            display: block;
            font-size: 35px;
            position: absolute;
            bottom: -98px;
            right: 240px;
            transition: $transition;

            &:hover {
                color: $black-color;
            }
        }
    }

    .review-img {
       
    position: relative;


        img {
            &:nth-child(1) {
                position: relative;
                height: 400px;
            }

            &:nth-child(2) {
                position: absolute;
                top: 60px;
                right: 0;
            }
        }
    }
.flex-slider-home{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
    .review-item {
        text-align: center;
        max-width: 560px;
        padding-top: 100px;
        padding-bottom: 100px;

        .section-title {
            margin-bottom: 30px;
        }

        .slider-nav {
            margin-bottom: 40px;

            .slick-center {
                img {
                    transform: scale(1.3);
                    margin-top: 12px;
                    margin-bottom: 12px;
                }
            }

            img {
                margin-left: auto;
                margin-right: auto;
                margin-top: 12px;
                transition: $transition;
                max-width: 200px;

                &:focus {
                    border: 0;
                    box-shadow: none;
                }
            }
        }

        .slider-for {
            h3 {
                font-weight: 500;
                font-size: 20px;
                font-family: $common-font;
                margin-bottom: 15px;
            }

            p {
                margin-bottom: 0;
                max-width: 500px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

/*-- End Review --*/

/*-- Blog --*/
.blog-item {
    margin-bottom: 50px;
    box-shadow: 0px 0px 20px 0px #dddddd8c;
    border-radius: 65px 65px 0 0;
    // width: 300px;
    height: 335px;

    &:hover {
        .blog-bottom {
            .cmn-btn {
                right: 0;
            }
        }
    }

    .blog-top {
        position: relative;

        a {
            display: block;
        }

        span {
            display: inline-block;
            font-size: 14px;
            color: $black-color;
            background-color: $olive-color;
            padding: 5px 12px;
            border-radius: 6px;
            position: absolute;
            right: 15px;
            bottom: -15px;
        }

        img {
            border-radius: 12px 12px 0 0;
            width: 100%;
            height: 200px;
        }
    }

    .blog-bottom {
        padding: 35px 15px 50px 30px;
        position: relative;

        h3 {
            font-family: $common-font;
            font-weight: 500;
            font-size: 22px;
            margin-bottom: 10px;
            line-height: 1.4;

            a {
                display: block;
                color: $black-color;

                &:hover {
                    color: $olive-color;
                }
            }
        }

        p {
            color: $gray-color;
            margin-bottom: 0;
        }

        .cmn-btn {
            position: absolute;
            right: 15px;
            bottom: -20px;
        }
    }
}

.blog-area {

    .section-title{
        text-align: start;

        p{
            margin-left: 0;
        }
    }
    .read-blog-btn {
        text-align: center;
        display: inline-block;
        font-weight: 500;
        font-size: 18px;
        color: $black-color;
        border-bottom: 1px solid $black-color;

        &:hover {
            color: $olive-color;
            border-bottom: 1px solid $olive-color;
        }
    }
}

/*-- End Blog --*/

/*-- Subscribe --*/
.subscribe-area {
    background-color: $black-color;
    border-radius: 0 0 75px 0;
    position: relative;

    .subscribe-shape {
        img {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
    }
}

.subscribe-item {
    padding-top: 100px;
    padding-bottom: 100px;

    .section-title {
        text-align: left;
        margin-bottom: 35px;

        h2 {
            color: $white-color;
        }

        p {
            color: $white-color;
            margin-left: 0;
        }
    }

    .newsletter-form {
        position: relative;
        margin-bottom: 30px;

        .form-control {
            height: 70px;
            padding: 10px 15px 10px 30px;
            border-radius: 50px;
            background-color: $white-color;
            border: 0;
            font-size: 16px;

            ::placeholder {
                color: $gray-color;
            }

            &:focus {
                box-shadow: none;
                border: 0;
            }
        }

        .cmn-btn {
            opacity: 1;
            position: absolute;
            right: 10px;
            top: 10px;
            transition: $transition;
            padding: 12px 40px;
        }

        .validation-danger {
            font-size: 14px;
            color: $white-color;
            margin-top: 10px;
        }
    }

    .social-link {
        ul {
            position: relative;
            z-index: 2;
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                margin-right: 12px;

                a {
                    display: block;
                    width: 40px;
                    height: 40px;
                    line-height: 46px;
                    border-radius: 50%;
                    text-align: center;
                    font-size: 20px;
                    color: $black-color;
                    background-color: $white-color;

                    &:hover {
                        display: block;
                        background-color: $olive-color;
                        color: $white-color;
                    }
                }
            }
        }
    }
}

.subscribe-img {
    text-align: center;

    img {
        border-radius: 42px;
    }
}

/*-- End Subscribe --*/

/*-- Footer --*/
.footer-item {
    margin-bottom: 30px;

    .footer-logo {
.bxl-instagram{
    color: #000;
}
        a {
            display: block;
            margin-bottom: 25px;
        }

        p {
            color: $white-color;
            margin-bottom: 25px;
        }

        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                margin-right: 5px;

                a {
                    display: block;
                    width: 35px;
                    height: 35px;
                    line-height: 39px;
                    border-radius: 50%;
                    text-align: center;
                    font-size: 18px;
                    color: $white-color;
                    background-color: $white-color;
                    margin-bottom: 0;

                    &:hover {
                        background-color: $olive-color;
                    }
                }
            }
        }
    }

    .footer-services {
        h3 {
            font-weight: 500;
            font-size: 22px;
            font-family: $common-font;
            margin-bottom: 30px;
            color: $white-color;
        }

        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: block;
                margin-bottom: 15px;
                color: $white-color;
                position: relative;
                padding-left: 28px;

                i {
                    display: inline-block;
                    font-size: 20px;
                    margin-right: 3px;
                    position: absolute;
                    top: 3px;
                    left: 0;
                }

                a {
                    display: block;
                    color:$white-color;

                    &:hover {
                        margin-left: 10px;
                        color: $olive-color;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

/*-- End Footer --*/

/*-- Copyright --*/
.copyright-area {
    background-color: $black-color;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 35px 35px 0 0;

    .copyright-item {
        text-align: center;

        p {
            color: $white-color;
            font-size: 15px;
            margin-bottom: 0;

            a {
                display: inline-block;
                color: $olive-color;
                font-weight: 500;

                &:hover {
                    color: $white-color;
                }
            }
        }
    }
}

/*-- End Copyright --*/

/*-- Navbar --*/
.main-nav-two {
    .nav-two-logo-one {
        display: block;
    }

    .nav-two-logo-two {
        display: none;
    }

    nav {
        .navbar-nav {
            .nav-item {

                a {
                    color: $white-color;
                }

                .dropdown-menu {
                    li {

                        &:hover {
                            a {
                                color: $olive-color;
                            }
                        }

                        a {
                            color: $black-color;
                        }
                    }
                }
            }
        }

        .side-nav {
            .nav-cart {
                border: 1px solid $white-color;
                color: $white-color;

                &:hover {
                    color: $white-color;
                    background-color: $black-color;
                    border: 1px solid $black-color;
                }

                span {
                    color: $black-color;
                    background-color: $white-color;
                }
            }

            .modal-btn {
                width: 42px;
                height: 42px;
                line-height: 48px;
                background-color: $olive-color;
                border-radius: 50%;
                padding: 0;
                font-size: 28px;
                margin-left: 15px;
                position: relative;
                top: -7px;
                transition: $transition;

                &:hover {
                    color: $white-color;
                    background-color: $black-color;
                }
            }
        }
    }

    &.menu-shrink {
        .nav-two-logo-one {
            display: none;
        }

        .nav-two-logo-two {
            display: block;
        }

        nav {
            .navbar-nav {
                .nav-item {

                    a {
                        color: $black-color;

                        &:focus,
                        &:hover,
                        &.active {
                            color: $olive-color;
                        }
                    }
                }
            }

            .side-nav {
                .nav-cart {
                    border: 1px solid $olive-color;
                    color: $black-color;
                    background-color: $olive-color;

                    &:hover {
                        color: $white-color;
                        background-color: $black-color;
                        border: 1px solid $black-color;
                    }

                    span {
                        color: $olive-color;
                        background-color: $black-color;
                    }
                }
            }
        }
    }
}

#myModalRight {
    z-index: 99999;

    .modal-content {
        .modal-header {
            img {
                max-width: 120px;
            }

            .close {
                position: relative;
                top: 3px;
            }
        }

        .modal-body {
            padding: 40px 30px 50px;

            h2 {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 12px;
                color: $black-color;
            }

            p {
                color: $gray-color;
                font-size: 14px;
                margin-bottom: 20px;
            }

            .image-area {
                margin-bottom: 10px;

                .col-lg-4 {
                    padding-right: 5px;
                    margin-right: -5px;
                }

                a {
                    display: block;
                    margin-bottom: 15px;
                }
            }

            .modal-item {
                margin-bottom: 30px;
            }

            .social-area {
                text-align: center;

                h3 {
                    font-size: 20px;
                    margin-bottom: 12px;
                    font-weight: 600;
                    color: $black-color;
                }

                ul {
                    margin: 0;
                    padding: 0;

                    li {
                        list-style-type: none;
                        display: inline-block;

                        a {
                            display: block;
                            color: $black-color;
                            border: 1px solid $black-color;
                            width: 32px;
                            height: 32px;
                            line-height: 34px;
                            border-radius: 50%;
                            margin-right: 3px;
                            margin-left: 3px;
                            font-size: 16px;
                            text-align: center;

                            &:hover {
                                color: $white-color;
                                background-color: $black-color;
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal {
    &.modal-right {
        .modal-dialog {
            max-width: 380px;
            min-height: 100vh;
        }

        &.show .modal-dialog {
            transform: translate(0, 0);
        }

        .modal-content {
            height: 100vh;
            overflow-y: auto;
            border-radius: 0;
        }
    }

    &.modal-left {
        .modal-dialog {
            transform: translate(-100%, 0);
            margin: 0 auto 0 0;
        }
    }

    &.modal-right {
        .modal-dialog {
            transform: translate(100%, 0);
            margin: 0 0 0 auto;
        }
    }
}

/*-- End Navbar --*/

/*-- Banner --*/
.banner-area-two {
    background-image: url('assets/img/home-two/banner/banner1.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: $black-color;
        opacity: .60;
    }

    .banner-shape {
        img {
            position: absolute;

            &:nth-child(1) {
                bottom: 60px;
                left: 70px;
                max-width: 120px;
            }

            &:nth-child(2) {
                top: 100px;
                left: 100px;
                max-width: 120px;
            }

            &:nth-child(3) {
                top: 245px;
                left: 680px;
                max-width: 120px;

            }
        }
    }

    .banner-content {
        position: relative;
        padding-top: 230px;
        padding-bottom: 200px;

        h1 {
            color: $white-color;
            font-weight: 700;
            font-size: 65px;
            margin-bottom: 20px;
            max-width: 700px;
        }

        p {
            color: $white-color;
            margin-bottom: 35px;
            max-width: 575px;
            margin-left: 0;
        }

        .banner-btn-wrap {
            .cmn-btn {
                margin-right: 18px;
            }

            .banner-btn-two {
                font-weight: 500;
                color: $white-color;
                border: 1px solid $white-color;
                border-radius: 50px;
                display: inline-block;
                padding: 11px 30px;

                &:hover {
                    background-color: $white-color;
                    color: $black-color;
                }
            }
        }
    }

    .banner-img {
        position: absolute;
        bottom: 0;
        right: 80px;

        img {
            max-width: 415px;
        }
    }
}

/*-- End Banner --*/

/*-- Food Image --*/
.food-img-area {
    margin-top: -55px;
    position: relative;
    z-index: 1;

    .food-img-item {
        text-align: center;
        transition: $transition;
        margin-bottom: 30px;

        &:hover {
            transform: scale(1.1);
        }

        img {
            max-width: 100px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

/*-- End Food Image --*/

/*-- About --*/
.about-area {
    position: relative;

    .about-shape {
        img {
            position: absolute;

            &:nth-child(1) {
                right: 0;
                top: 95px;
                max-width: 780px;
                z-index: -1;
            }

            &:nth-child(2) {
                right: 98px;
                top: 92px;
            }

            &:nth-child(3) {
                right: 255px;
                top: 138px;
            }

            &:nth-child(4) {
                right: 450px;
                top: 108px;
            }

            &:nth-child(5) {
                right: 650px;
                top: 88px;
            }
        }
    }

    .about-img {
        position: relative;
        text-align: right;

        img {
            &:nth-child(1) {
                position: absolute;
                left: 0;
                bottom: 0;
            }

            &:nth-child(2) {
                position: relative;
                max-width: 335px;
                right: 50px;
            }
        }
    }

    .about-content {
        max-width: 620px;
        margin-top: 100px;
        padding-left: 40px;

        .section-title {
            text-align: left;
            margin-bottom: 35px;

            h2 {
                margin-bottom: 25px;
            }

            p {
                margin-left: 0;
                margin-bottom: 10px;
            }
        }
    }
}

.services-area-two {
    background-color: unset;
}

.collection-area-two {
    background-color: $white-color;
}

/*-- End About --*/

/*-- Download --*/
.download-area {
    .download-content {
        margin-bottom: 30px;

        .section-title {
            text-align: left;
        }

        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: block;
                padding: 20px 20px 20px;
                background-color: $white-color;
                border-radius: 10px;
                box-shadow: 0px 0px 20px 0px #dddddd52;
                margin-bottom: 30px;
                max-width: 350px;
                font-weight: 500;
                font-size: 20px;
                color: $black-color;
                transition: $transition;
                border: 1px solid transparent;

                &:hover {
                    margin-left: 15px;
                    border: 1px solid $olive-color;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                span {
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    border-radius: 50%;
                    text-align: center;
                    background-color: $olive-color;
                    color: $black-color;
                    display: inline-block;
                    font-weight: 600;
                    font-size: 25px;
                    margin-right: 15px;
                    box-shadow: 0px 0px 20px 0px #dddddd52;
                }
            }
        }

        .app-wrap {
            margin-top: 50px;

            a {
                display: inline-block;
                margin-right: 20px;
                max-width: 180px;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }

    .download-img {
        margin-bottom: 30px;
        text-align: center;

        img {
            animation: download-animation 3s infinite linear;
        }
    }
}

@keyframes download-animation {
    0% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(0, -20px);
    }

    100% {
        transform: translate(0, 0);
    }
}

/*-- End Download --*/

/*-- Join --*/
.join-area {
    background-color: #FFFFE9;
    margin-top: 130px;

    .join-img {
        position: relative;
        text-align: center;

        img {
         position: absolute;
    left: 0;
    top: -62px;
    max-width: 500px;
    right: 0;
    margin-left: auto;
    margin-right: 120px;
        }
    }

    .join-content {
        padding-top: 100px;
        padding-bottom: 100px;

        .section-title {
            margin-bottom: 30px;
            text-align: left;

            h2 {
                color: black;
                margin-bottom: 22px;
            }

            p {
                color: $white-color;
                margin-left: 0;
            }
        }

        .cmn-btn {
            border: 1px solid $white-color;
            background-color: black;
            color:$white-color;

            &:hover {
                background-color: $olive-color;
                border-color: $olive-color;
            }
        }
    }
}

.chef-area-two {
    padding-top: 100px;
}

/*-- End Join --*/

/*-- Review --*/
.review-area-two {
    background-color: $black-color;
    position: relative;
    padding: 25px;

    .review-shape {
        img {
            position: absolute;
            bottom: 0;
            right: 0;
            max-width: 200px;
            animation: review-animation 8s infinite linear;
        }
    }

    .review-img {
        text-align: center;

        img {
            border-radius: 42px;
        }
    }

    .review-item {
        padding-top: 100px;
        padding-bottom: 150px;

        .section-title {
            h2 {
                color: $olive-color;
            }

            p {
                color: $white-color;
            }
        }

        .slider-for {
            h3 {
                color: $olive-color;
            }

            p {
                color: $white-color;
            }
        }
    }

    .slick-prev {
        i {
            left: 230px;

            &:hover {
                color: $white-color;
            }
        }
    }

    .slick-next {
        i {
            right: 230px;

            &:hover {
                color: $white-color;
            }
        }
    }

    @keyframes review-animation {
        0% {
            transform: translate(0, 0);
        }

        50% {
            transform: translate(-50px, 0);
        }

        10% {
            transform: translate(0, 0);
        }
    }
}

/*-- End Review --*/

/*-- Footer --*/
.footer-area-two {
    background-color: $white-color;
    border-radius: 45px 45px 0 0;
    border-bottom: 1px solid #8788988c;

    .footer-item {
        .footer-logo {
            p {
                color: $white-color;
            }
        }

        .footer-services {
            h3 {
                color: $olive-color;
            }

            ul {
                li {
                    color: $white-color;

                    a {
                        color: $white-color;

                        &:hover {
                            color: $olive-color;
                        }
                    }
                }
            }
        }
    }
}

/*-- End Footer --*/

/*-- Copyright --*/
.copyright-area-two {
    border-radius: 0;
}

/*-- End Copyright --*/

/*-- Banner --*/
.banner-area-three {
    background-attachment: fixed;
    background-image: url('assets/img/home-three/IMG_1409.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
        height: calc(100vh - 10vh);

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: $white-color;
        opacity: .50;
    }

    .banner-shape {
        img {
            position: absolute;

            &:nth-child(1) {
                bottom: 35px;
                left: 140px;
                max-width: 120px;
            }

            &:nth-child(2) {
                top: 85px;
                left: 45px;
                max-width: 120px;
            }

            &:nth-child(3) {
                bottom: 0;
                right: 20px;
                max-width: 120px;

            }
        }
    }

    .banner-content {
        position: relative;
        padding-top: 300px;
        padding-bottom: 150px;

        h1 {
            color: #000;
            font-weight: 700;
            font-size: 65px;
            margin-bottom: 20px;
            max-width: 700px;
        }

        p {
            color: #000;
            font-weight: 500;
            margin-bottom: 35px;
            max-width: 575px;
            margin-left: 0;
        }

        .banner-btn-wrap {
            .cmn-btn {
                margin-right: 18px;
            }

            .banner-btn-two {
                font-weight: 500;
                color: #000;
                background-color: $olive-color;
                border: 1px solid  $olive-color;
                border-radius: 50px;
                display: inline-block;
                padding: 11px 30px;

                &:hover {
                    background-color: transparent;
                    border: 1px solid black;
                                    color: #000;

                }
            }
        }
    }

    .banner-img {
        position: absolute;
        bottom: 140px;
        right: 40px;

        img {
            max-width: 505px;
            border-radius: 12px;
        }
    }
}

/*-- End Banner --*/

/*-- About --*/
.about-area-two {
    position: relative;

    .about-shape {
        img {
            position: absolute;
            bottom: 60px;
            right: 65px;
            max-width: 190px;
            animation: review-animation 8s infinite linear;
        }
    }

    .about-img {
        margin-bottom: 80px;
        position: relative;
        text-align: center;

        img {
            &:nth-child(1) {
                border-radius: 10px;
                max-width: 470px;
                margin-left: auto;
                margin-right: auto;
                top: 28px;
                position: relative;
            }

            &:nth-child(2) {
                position: absolute;
                left: 0;
                top: 0;
            }
        }

        .video-wrap {
            position: absolute;
            bottom: 0;
            right: -35px;

            a {
                z-index: 10;
                display: block;
                width: 100px;
                height: 100px;
                line-height: 110px;
                border-radius: 50%;
                position: relative;
                font-size: 60px;
                text-align: center;
                color: $white-color;

                &:before {
                    content: "";
                    position: absolute;
                    z-index: 0;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    display: block;
                    width: 100px;
                    height: 100px;
                    background-color: $black-color;
                    border-radius: 50%;
                    z-index: -1;
                    animation: pulse-border 1500ms ease-out infinite;
                }

                &:after {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    display: block;
                    width: 100px;
                    height: 100px;
                    background-color: $black-color;
                    border-radius: 50%;
                    z-index: -1;
                    transition: $transition;
                }

                &:hover {
                    color: $white-color;

                    &:before {
                        background-color: $olive-color;
                    }

                    &:after {
                        background-color: $olive-color;
                    }
                }
            }
        }
    }

    .about-content {
        margin-bottom: 30px;
        padding-left: 60px;

        .section-title {
            text-align: left;
            margin-bottom: 32px;

            p {
                margin-bottom: 10px;
            }
        }
    }

    @keyframes pulse-border {
        0% {
            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
            opacity: 1;
        }

        100% {
            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
            opacity: 0;
        }
    }
}

/*-- End About --*/

/*-- Service --*/
.services-area-three {
    background-color: $black-color;

    .services-item {
        .section-title {
            text-align: left;

            h2 {
                color: $white-color;
            }

            p {
                color: $white-color;
                margin-left: 0;
            }
        }

        .accordion {
            padding-left: 0;
            margin-bottom: 0;

            a {
                padding: 13px 15px;
                border-radius: 8px;
                background-color: $white-color;
                width: 100%;
                font-weight: 500;
                display: block;
                cursor: pointer;
                font-size: $all-size;
                color: $white-color;
                color: $black-color;

                &:after {
                    position: absolute;
                    content: '+';
                    width: 15px;
                    height: 2px;
                    right: 12px;
                    top: 10px;
                    font-size: 20px;
                    color: $black-color;
                    background-color: transparent;
                }

                span {
                    display: inline-block;
                    font-weight: 600;
                    color: $black-color;
                    font-size: $all-size;
                    margin-right: 10px;
                }
            }

            .active {
                &:after {
                    content: 'x';
                    top: 11px;
                    font-size: 17px;
                }
            }

            p {
                display: none;
                margin-bottom: 0;
                color: $white-color;
                font-size: 15px;
                padding: 15px 8px 5px 10px;
            }

            li {
                position: relative;
                list-style-type: none;
                margin-bottom: 30px;
            }
        }
    }

    .services-img {
        margin-bottom: 30px;
        text-align: center;

        img {
            // max-width: 500px;
            margin-left: auto;
            margin-right: auto;
            // animation: service-two-animation 10s infinite linear;
        }
    }
}

@keyframes service-two-animation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.join-area-two {
    margin-top: 132px;
}

/*-- End Service --*/

/*-- Footer --*/
.footer-item {
    .footer-logo {
        .footer-subscribe {
            position: relative;

            .form-control {
                height: 45px;
                border-radius: 50px;
                box-shadow: 0px 0px 20px 0px #dddddd7d;
                border: 0;
                padding-left: 20px;
                font-size: 15px;
            }

            ::placeholder {
                color: $gray-color;
            }

            .footer-btn {
                width: 35px;
                height: 35px;
                line-height: 37px;
                font-size: 18px;
                border-radius: 50%;
                color: $black-color;
                background-color: $olive-color;
                transition: $transition;
                position: absolute;
                top: 5px;
                right: 6px;
                padding: 0;

                &:hover {
                    color: $white-color;
                    background-color: $black-color;
                }
            }
        }
    }
}

/*-- End Footer --*/

/*----- About CSS -----*/
/*-- Navbar --*/
.main-nav-three {
    background-color: transparent;
}


/*-- End Navbar --*/

/*-- Page Title --*/
.page-title-img-one {
    background-image: url('assets/img/about/pagetitle.jpg');
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: .50;
        background-color: $white-color;
    }
}

.page-title-area {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 230px;
    padding-bottom: 135px;
}

.page-title-item {
    padding-left: 40px;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 10px;
        height: 100px;
        left: 0;
        top: 0;
        border-radius: 50px;
        background-color: $olive-color;
    }

    h2 {
        font-size: 40px;
        // color: $gray-color;
        margin-bottom: 10px;
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            display: inline-block;
            color: $olive-color;

            i {
                display: inline-block;
                font-size: 25px;
                position: relative;
                top: 6px;
                // color: $white-color;
            }

            a {
                display: inline-block;
                color: $black-color;

                &:hover {
                    color: $olive-color;
                }
            }
        }
    }
}

/*-- End Page Title --*/

/*-- Story --*/
.story-area {
    text-align: center;
    position: relative;

    .story-shape {
        img {
            position: absolute;
            right: 0;
            bottom: 0;
            max-width: 135px;
        }
    }

    .story-head {
        // margin-bottom: 10px;

        h2 {
            font-weight: 700;
            font-size: 36px;
            margin-bottom: 25px;
            margin-top: -10px;
        }

        p {
            max-width: 825px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0;
        }

        h5 {
            color: $olive-color;
        }
    }

    .story-item {
        margin-bottom: 30px;
        position: relative;
        transition: $transition;

        &:hover {
            transform: translate(0, -10px);

            h3 {
                transform: translate(0, 10px);
                color: $white-color;
                background-color: $olive-color;
            }
        }

        img {
            width: 100%;
            border-radius: 12px;
        }

        h3 {
            text-align: center;
            font-weight: 600;
            font-size: 20px;
            font-family: $common-font;
            background-color: $white-color;
            border-radius: 10px;
            max-width: 370px;
            margin-left: auto;
            margin-right: auto;
            padding: 18px;
            margin-bottom: 0;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 30px;
            transition: $transition;
        }
    }
}

/*-- End Story --*/

/*-- Download --*/
.download-area-two {
    background-color: $black-color;
    position: relative;
    border-radius: 0 0 80px 0;

    .download-shape {
        img {
            position: absolute;

            &:nth-child(1) {
                left: 0;
                top: 0;
            }

            &:nth-child(2) {
                left: 100px;
                bottom: 20px;
                animation: download-one 10s infinite linear;
            }
        }
    }

    .download-item {
        margin-bottom: 30px;

        h2 {
            color: $white-color;
            font-weight: 700;
            font-size: 35px;
            margin-bottom: 40px;
        }

        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }

                a {
                    display: block;
                    max-width: 180px;

                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }

    .download-img {
        position: relative;

        img {
            position: absolute;
            top: -55px;
            right: 0;
            left: 0;
            max-width: 460px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@keyframes download-one {
    0% {
        transform: translate(0, 0);
    }

    30% {
        transform: translate(0, -100px);
    }

    70% {
        transform: translate(100px, 0);
    }

    100% {
        transform: translate(0, 0);
    }
}

.footer-item {
    .footer-logo {
        .footer-subscriber-two {
            .form-control {
                box-shadow: none;
            }
        }
    }
}

/*-- End Download --*/

/*-- Service --*/
.services-area-four {
    .services-item {
        border: 1px solid $olive-color;
        margin-bottom: 30px;
    }
}

/*-- End Service --*/
/*----- End About CSS -----*/

/*----- Service Details CSS -----*/
/*-- Page Title --*/
.page-title-area-two {
    background-color: $black-color;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        background-color: $olive-color;
        clip-path: polygon(70% 0, 100% 0%, 100% 100%, 40% 100%);
    }

    .page-title-plate {
        ul {
            margin: 0;
            padding: 0;
            margin-left: 75px;

            li {
                list-style-type: none;
                display: inline-block;
                position: relative;

                img {
                    display: inline-block;
                    max-width: 130px;
                }

                &:nth-child(1) {
                    z-index: 4;
                }

                &:nth-child(2) {
                    left: -25px;
                    top: 0;
                    z-index: 3;
                }

                &:nth-child(3) {
                    left: -55px;
                    top: 0;
                    z-index: 2;
                }

                &:nth-child(4) {
                    left: -85px;
                    top: 0;
                }
            }
        }
    }
}

/*-- End Page Title --*/

/*-- Service Details --*/
.services-details-area {
    .services-details-item {
        margin-bottom: 30px;

        .services-details-more {
            h3 {
                font-weight: 700;
                font-size: 25px;
                margin-bottom: 30px;
            }

            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: block;
                    box-shadow: 0px 0px 20px 0px #dddddd8a;
                    margin-bottom: 20px;
                    position: relative;
                    border-radius: 10px;
                    transition: $transition;

                    &:hover {
                        box-shadow: none;

                        a {
                            color: $white-color;
                            background-color: $olive-color;
                            cursor: pointer;
                        }
                    }

                    a {
                        display: block;
                        font-weight: 500;
                        font-size: $all-size;
                        color: $black-color;
                        padding: 15px 20px 15px;
                        border-radius: 10px;

                        i {
                            display: inline-block;
                            position: absolute;
                            top: 18px;
                            right: 17px;
                            font-weight: 700;
                        }
                    }
                }
            }
        }

        .services-details-order {
            text-align: center;
            background-color: $black-color;
            border-radius: 10px;
            padding: 30px 10px 0;
            position: relative;
            margin-top: 30px;

            h3 {
                font-weight: 700;
                font-size: 20px;
                color: $white-color;
                margin-bottom: 10px;
            }

            span {
                display: block;
                color: $white-color;
                margin-bottom: 30px;
            }

            .offer-off {
                background-color: $olive-color;
                width: 70px;
                height: 70px;
                border-radius: 50%;
                text-align: center;
                padding-top: 8px;
                position: absolute;
                bottom: 135px;
                right: 40px;

                span {
                    display: block;
                    font-weight: 600;
                    font-size: 18px;
                    color: $black-color;
                    margin-bottom: 0;
                }
            }
        }

        .services-details-fresh {
            h2 {
                font-weight: 700;
                font-size: 35px;
                margin-bottom: 15px;
            }

            p {
                margin-bottom: 25px;
            }

            img {
                margin-bottom: 25px;
                border-radius: 18px;
            }

            .services-details-p {
                margin-top: -15px;
            }
        }
    }
}

/*-- End Service Details --*/
/*----- End Service Details CSS -----*/

/*----- Blog Details CSS -----*/
/*-- Page Title --*/
.page-title-img-two {
    background-image: url('assets/img/blog-details/1.jpg');
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: $black-color;
        clip-path: polygon(0 0, 60% 0%, 40% 100%, 0% 100%);
        opacity: .90;
    }
}

/*-- End Page Title --*/

/*-- Service Details --*/
.blog-details-more {
    padding-bottom: 20px;
}

.blog-details-tags {
    h3 {
        font-weight: 700;
        font-size: 25px;
        margin-bottom: 30px;
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            display: inline-block;
            margin-right: 5px;
            margin-bottom: 10px;

            a {
                display: block;
                font-size: 12px;
                color: $gray-color;
                background-color: $white-color;
                border-radius: 30px;
                padding: 7px 16px;

                &:hover {
                    color: $white-color;
                    background-color: $black-color;
                }
            }
        }
    }
}

.blog-details-nav {
    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            display: inline-block;
            margin-right: 12px;

            a {
                display: block;
                font-weight: 500;
                font-size: $all-size;
                color: $black-color;
                border: 1px solid $black-color;
                padding: 9px 25px;
                border-radius: 50px;

                &:hover {
                    border: 1px solid $olive-color;
                    background-color: $olive-color;
                    color: $black-color;
                }
            }
        }
    }
}

/*-- End Service Details --*/
/*----- End Blog Details CSS -----*/

/*----- Cart CSS -----*/
/*-- Table --*/
.cart-wrap {
    text-align: center;

    .table {
        margin: 0;

        .thead {
            background-color: $olive-color;

            tr {
                .table-head {
                    color: $white-color;
                    font-size: 18px;
                    padding-top: 18px;
                    padding-bottom: 18px;
                }
            }
        }

        tr {
            .table-item {
                img {
                    width: 50px;
                }
            }

            td {
                font-size: $all-size;
                color: $black-color;

                a {
                    color: $black-color;
                    display: block;
                    font-weight: 700;

                    &:hover {
                        color: $olive-color;
                    }
                }
            }
        }

        td,
        th {
            border: 1px solid #dee2e6;
            vertical-align: middle;
        }
    }

    .shop-back {
        margin-bottom: 20px;

        a {
            display: block;
            color: $black-color;
            font-weight: 500;
            margin-top: 20px;
            font-size: 15px;

            &:hover {
                color: $olive-color;
            }
        }
    }

    .total-shopping {
        h2 {
            color: $black-color;
            font-size: 35px;
            padding-bottom: 5px;
            border-bottom: 1px solid #000;
            display: inline-block;
            margin: 0;
            margin-bottom: 20px;
            font-weight: 700;
        }

        h3 {
            color: $black-color;
            font-size: 20px;

            span {
                display: inline-block;
                margin-left: 70px;
            }
        }

        a {
            margin-top: 20px;
            display: inline-block;
            color: $white-color;
            background-color: $olive-color;
            font-size: 17px;
            padding: 15px 60px;

            &:hover {
                background-color: $black-color;
            }
        }
    }
}

/*-- End Table --*/
/*----- End Cart CSS -----*/

/*----- Checkout CSS -----*/
/*-- Checkout --*/
.checkout-area {
    .cmn-btn {
        padding: 12px 45px;
        transition: $transition;
    }
}

.checkout-item {
    border: 1px solid #dddddd40;
    margin-bottom: 30px;

    h2 {
        text-align: center;
        font-size: 35px;
        margin-bottom: 50px;
        background-color: $olive-color;
        padding-top: 15px;
        padding-bottom: 15px;
        font-weight: 700;
        color: $white-color;
    }

    .checkout-one {
        max-width: 515px;
        margin: auto;
        padding-bottom: 20px;

        label {
            color: $black-color;
            font-size: 15px;
            margin-right: 10px;
            position: relative;
            top: 2px;
            font-weight: 500;
        }

        .form-group {
            margin: 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 30px;

            .form-control {
                display: inline-block;
                width: 78%;
                background-color: $gray-color;
                border: 1px solid transparent;
                border-radius: 0;
                height: 45px;
                font-size: 15px;

                &:focus {
                    border: 1px solid $olive-color;
                    box-shadow: none;
                }
            }
        }
    }

    .checkout-two {
        max-width: 460px;
        margin: auto;
        padding-bottom: 45px;

        h3 {
            margin: 0;
            margin-bottom: 15px;
            font-size: 22px;
            font-weight: 500;
            font-family: $common-font;
        }

        p {
            font-size: 15px;
            margin-bottom: 10px;
        }

        .form-check {
            margin-top: 15px;
            margin-bottom: 15px;

            span {
                display: inline-block;
                color: $gray-color;
                font-size: 16px;

                a {
                    color: $gray-color;

                    &:hover {
                        color: $black-color;
                    }
                }
            }

            .form-check-input {
                margin-top: 5px;
                width: 14px;
                height: 14px;
            }
        }

        .text-center {
            position: relative;

            &:before {
                position: absolute;
                content: '';
                width: 100%;
                height: 1px;
                left: 0;
                top: 14px;
                background-color: $gray-color;
            }

            span {
                display: inline-block;
                color: $black-color;
                font-size: 15px;
                margin-bottom: 20px;
                background-color: $white-color;
                position: relative;
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        ul {
            margin: 0;
            padding: 0;
            text-align: center;

            li {
                list-style-type: none;
                display: inline-block;
                margin-right: 4px;
                margin-left: 4px;

                a {
                    border: 1px solid transparent;
                    display: block;

                    &:hover {
                        border: 1px solid $olive-color;
                    }
                }
            }
        }
    }
}

/*-- End Checkout --*/
/*----- End Checkout CSS -----*/

/*----- Coming Soon CSS -----*/
/*-- Coming --*/
.coming-item {
    height: 100vh;
    text-align: center;

    h1 {
        font-size: 75px;
        font-weight: 700;
        font-style: italic;
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 40px;
        max-width: 865px;
        margin-left: auto;
        margin-right: auto;
    }

    // h5 {
    //     max-width: 865px;
    //     margin-left: auto;
    //     margin-right: auto;
    //     color: $gray-color;
    // }

    .coming-wrap {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;

        .coming-inner {
            text-align: center;
            background-color: $white-color;
            padding-top: 15px;
            padding-bottom: 12px;
            margin-bottom: 30px;

            h3 {
                font-size: 40px;
                font-weight: 600;
                color: $black-color;
                margin-bottom: 5px;
            }

            p {
                font-size: 16px;
                margin-bottom: 0;
            }
        }
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            display: inline-block;
            margin-right: 2px;
            margin-left: 2px;

            a {
                display: block;
                color: $white-color;
                background-color: $olive-color;
                width: 35px;
                height: 35px;
                line-height: 39px;
                border-radius: 50%;
                font-size: 16px;

                &:hover {
                    background-color: $black-color;
                }
            }
        }
    }
}

/*-- End Coming --*/
/*----- End Coming Soon CSS -----*/

/*----- FAQ CSS -----*/
/*-- FAQ --*/
.faq-area {
    .faq-head {
        h2 {
            margin-bottom: 35px;
            font-weight: 600;
            font-size: 30px;
            margin-top: -7px;
        }
    }

    .faq-wrap {
        margin-bottom: 50px;

        &:last-child {
            margin-bottom: 30px;
        }
    }

    .accordion {
        padding-left: 0;
        margin: 0;
        padding: 0;

        p {
            font-size: $all-size;
            display: none;
            padding: 20px 45px 15px 20px;
            margin-bottom: 0;
        }

        a {
            color: $black-color;
            font-size: 17px;
            width: 100%;
            display: block;
            cursor: pointer;
            font-weight: 600;
            padding: 15px 0 15px 18px;
            border: 1px solid #232323;
            border-radius: 8px 8px 0 0;

            &:hover {
                color: $black-color;
            }

            &:after {
                position: absolute;
                right: 20px;
                content: "+";
                top: 10px;
                color: $black-color;
                font-size: 25px;
                font-weight: 700;
            }
        }

        li {
            position: relative;
            list-style-type: none;
            margin-bottom: 30px;

            &:first-child {
                border-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .accordion {
        li {
            a.active {
                color: $white-color;
                background-color: $black-color;
                border: 1px solid $black-color;
            }

            a.active:after {
                content: "-";
                font-size: 25px;
                color: $white-color;
            }
        }
    }
}

/*-- End FAQ --*/
/*----- End FAQ CSS -----*/

/*----- Terms & Conditions CSS -----*/
/*-- Terms --*/
.privacy-area {
    padding-bottom: 50px;
}

.privacy-item {
    margin-bottom: 50px;

    h2 {
        font-size: 26px;
        margin-bottom: 15px;
        font-weight: 600;
        font-family: $common-font;
    }

    p {
        margin-bottom: 0;
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            display: block;
            margin-bottom: 18px;

            i {
                display: inline-block;
                font-size: 20px;
                position: relative;
                bottom: -2px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

/*-- End Terms --*/
/*----- End Terms & Conditions CSS -----*/

/*----- 404 CSS -----*/
/*-- 404 --*/
.error-item {
    height: 700px;
    text-align: center;
    margin-top: 25px;

    h1 {
        font-size: 130px;
        font-weight: 700;
        margin-bottom: 8px;
        font-family: $common-font;
    }

    p {
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 35px;
    }

    span {
        display: block;
    }

    a {
        display: inline-block;
        color: $white-color;
        background-color: $olive-color;
        border-radius: 10px;
        padding: 16px 40px;
        margin-top: 70px;
        font-size: 18px;

        &:hover {
            background-color: $black-color;
        }
    }
}

/*-- End 404 --*/
/*----- End 404 CSS -----*/

/*----- Contact CSS -----*/
/*-- Banner --*/
.page-title-img-three {
    background-image: url('assets/img/about/pagetitle.jpg');
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: .50;
        background-color: $white-color;
    }
}

/*-- End Banner --*/

/*-- Location --*/
.contact-location-area {
    background-color: $white-color;

    .location-item {
        text-align: center;
        background-color: $white-color;
        padding: 40px 20px 40px;
        border-radius: 20px;
        position: relative;
        margin-bottom: 30px;
        transition: $transition;
        z-index: 1;
        height: 200px;

        &:hover,
        &.active {
            background-color: $olive-color;

            img {
                opacity: 1;
            }

            i {
                color: $white-color;
                background-color: $black-color;
            }

            ul {
                li {
                    color: $white-color;
                }
            }
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: $transition;
            z-index: -1;
        }

        i {
            width: 65px;
            height: 65px;
            line-height: 65px;
            border-radius: 50%;
            text-align: center;
            display: block;
            font-size: 33px;
            color: $black-color;
            margin-bottom: 15px;
            margin-left: auto;
            margin-right: auto;
            transition: $transition;
            background-color: $olive-color;
        }

        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: block;
                color: $black-color;
                font-weight: 500;
                font-size: 16px;
                transition: $transition;
                margin-bottom: 5px;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    display: block;
                    color: $black-color;

                    &:hover {
                        color: $white-color;
                    }
                }
            }
        }
    }
}

/*-- End Location --*/

/*-- Form --*/
.contact-form-area {
    background-image: url('assets/img/contact-test.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    .contact-item {
        background-color: #ffffff9e;
        padding: 70px 50px;
        border-radius: 15px;

        #contactForm {
            margin-bottom: 40px;

            .form-group {
                margin-bottom: 20px;

                .form-control {
                    height: 55px;
                    border-radius: 30px;
                    padding-left: 30px;
                    border: 0;
                    background-color: $white-color;
                    font-size: 15px;

                    &:focus {
                        border: 0;
                        box-shadow: none;
                    }
                }

                ::placeholder {
                    color: $gray-color;
                }

                textarea {
                    height: auto !important;
                    padding-top: 10px;
                }
            }

            .cmn-btn {
                opacity: 1;
                transition: $transition;
                margin-top: 10px;
            }
        }

        .contact-social {
            span {
                display: block;
                font-size: 16px;
                color: $black-color;
                margin-bottom: 15px;
            }

            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: inline-block;
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }

                    a {
                        display: block;
                        width: 40px;
                        height: 40px;
                        line-height: 45px;
                        text-align: center;
                        border-radius: 50%;
                        color: $white-color;
                        background-color: $black-color;
                        font-size: 20px;

                        &:hover {
                            background-color: $olive-color;
                        }
                    }
                }
            }
        }

        .list-unstyled {
            color: #dc3545;
            margin-bottom: 0;
            margin-top: 10px;
        }

        .text-danger {
            color: #dc3545;
            margin-top: 14px;
            margin-bottom: 0;
        }

        .text-success {
            color: #28a745;
            margin-top: 14px;
            margin-bottom: 0;
        }
    }

    .contact-img {
        text-align: center;
        position: relative;

        img {
            position: absolute;
            left: 0;
            right: 0;
            top: 43px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

/*-- End Form --*/
/*----- End Contact CSS -----*/

/*----- Book A Table CSS -----*/
/*-- Book --*/
.book-table-area {
    .book-table-wrap {
        background-color: $white-color;
        padding: 60px 80px 65px;
        border-radius: 10px;
    }

    .form-group {
        margin-bottom: 30px;

        .form-control {
            height: 50px;
            border-radius: 5px;
            border: 1px solid #ddd;
            padding-left: 25px;
            font-size: 15px;

            &:focus {
                box-shadow: none;
                border: 1px solid $olive-color;
            }
        }

        textarea {
            height: auto !important;
            padding-top: 15px;
        }
    }

    .cmn-btn {
        transition: $transition;
    }
}

/*-- End Book --*/
/*----- End Book A Table CSS -----*/

/*----- Preloader CSS -----*/
.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: $black-color;
}

.spinner {
    width: 50px;
    height: 50px;
    margin: 100px auto;
    background-color: $black-color;
    border-radius: 100%;
    animation: sk-scaleout 1.0s infinite ease-in-out;
}

@keyframes sk-scaleout {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1.0);
        opacity: 0;
    }
}

/*----- End Preloader CSS -----*/

/*----- Back to Top CSS ------*/
#toTop {
    position: fixed;
    bottom: 30px;
    right: 0;
    cursor: pointer;
    display: none;
    z-index: 10;
}

.back-to-top-btn i {
    background: $olive-color;
    color: $white-color;
    height: 50px;
    width: 50px;
    line-height: 50px;
    display: inline-block;
    text-align: center;
    font-size: 30px;
    border-radius: 50%;
    transition: $transition;
    margin-right: 28px;
    box-shadow: 0px 0px 14px 0px $olive-color;
}

.back-to-top-btn i:hover {
    background-color: $black-color;
    color: $white-color;
    box-shadow: 0px 0px 14px 0px $black-color;
}

/*----- End Back to Top CSS -----*/

// toasr notification

// .toast-container {
    // border-radius: 22px !important;
    // margin-bottom: 2px !important;
// }

.toast-success {
    background-image: none !important;
    background-color: $olive-color !important;
}

.toast-error {
    background-image: none !important;
}

.toast-title {
    color: $white-color !important;
    font-size: 16px !important;
}

.toast-message {
    color: $white-color !important;
}
//   end of toastr notification